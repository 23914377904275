import { Routes, Route, useRouteError } from "react-router-dom";
import { Suspense, lazy } from "react";
import Layout from './components/Layout';
import Loading from "./components/Loading";
//import useTheme from "./hooks/useTheme";

//import { Login } from "./pages/Login"

import { ProtectedRoute } from "./components/ProtectedRoute";
import { AuthProvider } from "./hooks/useAuth";
import { SearchProvider } from "./hooks/useSearch";


const Home = lazy(() => import('./pages/Home'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Tos = lazy(() => import('./pages/Tos'));
const Car = lazy(() => import('./pages/Sell/Car'));
const Makes = lazy(() => import('./pages/Sell/Makes'));
const Models = lazy(() => import('./pages/Sell/Models'));
const Trims = lazy(() => import('./pages/Sell/Trims'));
const Person = lazy(() => import('./pages/Sell/Person'));
const About = lazy(() => import('./pages/About'));
const Team = lazy(() => import('./pages/Team'));
const Buy = lazy(() => import('./pages/Buy/index'));
const BuyItem = lazy(() => import('./pages/Buy/item'));
const CarEdit = lazy(() => import('./pages/Admin/CarEdit'));
const CarCreate = lazy(() => import('./pages/Admin/CarCreate'));
const CarMedia = lazy(() => import('./pages/Admin/CarMedia'));
const Login = lazy(() => import('./pages/Login'));
const Sell = lazy(() => import('./pages/Sell/Sell'));
const People = lazy(() => import('./pages/People'))
const Price = lazy(() => import('./pages/Sell/Price'))


export default function App() {

  const admin = window.location.hostname.indexOf(process.env.REACT_APP_SUBDOMAIN) === 0

  const HomePage = () => {
    if (window.location.hostname.indexOf("vende") === 0) {
      return <Sell />
    }
    return <Home />
  }


  return (
    <AuthProvider>
      <SearchProvider>
        <Routes>
          <Route path="/" element={<Layout admin={admin} />}>
            <Route index element={<Suspense fallback={<Loading />}>{HomePage()}</Suspense>} />
            <Route path="/privacy" element={<Suspense fallback={<Loading />}><Privacy /></Suspense>} />
            <Route path="/tos" element={<Suspense fallback={<Loading />}><Tos /></Suspense>} />
            <Route path="/about" element={<Suspense fallback={<Loading />}><About /></Suspense>} />
            <Route path="/team" element={<Suspense fallback={<Loading />}><Team /></Suspense>} />
            <Route path="/buy-car" element={<Suspense fallback={<Loading />}><Buy /></Suspense>} />
            <Route path="/buy-car/:id" element={<Suspense fallback={<Loading />}><BuyItem /></Suspense>} />
            <Route path="/login" element={<Suspense fallback={<Loading />}><Login /></Suspense>} />

            <Route path="/sell-car" element={<Suspense fallback={<Loading />}><Sell /></Suspense>} />

            <Route path="/admin/car/:id/edit" element={<Admin><CarEdit /></Admin>} />
            <Route path="/admin/car/:slug/media" element={<Admin><CarMedia /></Admin>} />
            <Route path="/admin/car/create" element={<Admin><CarCreate /></Admin>} />

            <Route path="/people/signature" element={<Suspense fallback={<Loading />}><People /></Suspense>} />

          </Route>

          <Route path="/sell-car/years" element={<Suspense fallback={<Loading />}><Car /></Suspense>} />
          <Route path="/sell-car/makes/:slug" element={<Suspense fallback={<Loading />}><Makes /></Suspense>} />
          <Route path="/sell-car/models/:slug" element={<Suspense fallback={<Loading />}><Models /></Suspense>} />
          <Route path="/sell-car/trims/:slug" element={<Suspense fallback={<Loading />}><Trims /></Suspense>} />
          <Route path="/sell-car/trims/:slug/:codia" element={<Suspense fallback={<Loading />}><Person /></Suspense>} />
          <Route path="/sell-car/:uuid/price" element={<Suspense fallback={<Loading />}><Price /></Suspense>} />



          

        </Routes>
      </SearchProvider>
    </AuthProvider>
  )
}




const Admin = ({ children }) => {
  return (
    <Suspense fallback={<Loading />}>
      <ProtectedRoute>{children}</ProtectedRoute>
    </Suspense>
  )
}




/* function NoMatch() {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}


 */